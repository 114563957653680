<form [formGroup]="patientForm" *ngIf="patientForm">
  <fieldset [disabled]="disableFields">
    <div class="row" formArrayName="addresses">
      <div class="col-12 col-lg-6">
        <h4>PRESENT ADDRESS</h4>
        <div class="row mt-3" formGroupName="0">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Country</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.country_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="country_code" (change)="updateDropdowns(0)">
              <option value="null" hidden>Select</option>
              <option *ngFor="let country of countries?.data" [value]="country?.code">
                {{ country?.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6">
            <span>Region</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.region_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[0]?.country_code === 'PHL'" class="form-control" formControlName="region_code" [attr.disabled]="!patientForm?.value?.addresses[0]?.country_code ? '' : null && countries?.data?.length < 1">
              <option value="null" hidden>Select</option>
              <option *ngFor="let region of regions?.data" [value]="region?.code">
                {{ region?.name }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[0]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="region_code" placeholder="Enter State"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Province / State</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.state_province_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[0]?.country_code === 'PHL'" class="form-control" formControlName="state_province_code" [attr.disabled]="!patientForm?.value?.addresses[0]?.region_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let province of filterProvince(0)" [value]="province?.code">
                {{ province?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[0]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="state_province_code" placeholder="Enter State"/>
          </div>
          <div class="col-12 col-md-6">
            <span>Municipality / City</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.city_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[0]?.country_code === 'PHL'" class="form-control" formControlName="city_code" [attr.disabled]="!patientForm?.value?.addresses[0]?.state_province_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let muni of filterMunicipality(0)" [value]="muni?.code">
                {{ muni?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[0]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="city_code" placeholder="Enter City"/>
          </div>
        </div>
        <div class="row mt-3" formGroupName="0">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Barangay</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.barangay_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[0]?.country_code === 'PHL'" class="form-control" formControlName="barangay_code" [attr.disabled]="!patientForm?.value?.addresses[0]?.city_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let barangay of filterBarangay(0)" [value]="barangay?.code">
                {{ barangay?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[0]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="barangay_code" placeholder="Enter Barangay"/>
          </div>
          <div class="col-12 col-md-6">
            <span>House / Lot / Building No.</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.line1')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="House / Lot / Building No." formControlName="line1"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Street / Purok / Sitio</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.line2')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Street / Purok / Sitio" formControlName="line2"/>
          </div>
          <div class="col-12 col-md-6">
            <span>Postal Code</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.zip_code')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Postal Code" formControlName="zip_code"/>
          </div>
          <div class="col-12 col-md-6 mt-3">
            <span>Notable Landmarks</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.0.landmark')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Notable Landmarks" formControlName="landmark"/>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="row mt-5 mt-md-3 mt-md-0">
          <div class="col-auto">
            <h4>PERMANENT ADDRESS</h4>
          </div>
          <div class="col-12 col-md-6" formGroupName="1">
            <div class="form-group" style="padding-top: 3px">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="same-present-address" value="option1" formControlName="is_same_present" (change)="updatePermantentAdderess()"/>
                <label for="same-present-address" class="custom-control-label text-nowrap">
                  <span style="font-weight: none !important">Same as Present Address</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" formGroupName="1">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Country</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.country_code')?.errors?.required">*</b>
            <select class="form-control" formControlName="country_code" (change)="updateDropdowns(1)">
              <option value="null" hidden>Select</option>
              <option *ngFor="let country of countries?.data" [value]="country?.code">
                {{ country?.name }}
              </option>
            </select>
          </div>
          <div class="col-12 col-md-6">
            <span>Region</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.region_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[1]?.country_code === 'PHL'" class="form-control" formControlName="region_code" [attr.disabled]="!patientForm?.value?.addresses[1]?.country_code ? '' : null && countries?.data?.length < 1">
              <option value="null" hidden>Select</option>
              <option *ngFor="let region of regions?.data" [value]="region?.code">
                {{ region?.name }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[1]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="region_code" placeholder="Enter State"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Province / State</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.state_province_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[1]?.country_code === 'PHL'" class="form-control" formControlName="state_province_code" [attr.disabled]="!patientForm?.value?.addresses[1]?.region_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let province of filterProvince(1)" [value]="province?.code">
                {{ province?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[1]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="state_province_code" placeholder="Enter State"/>
          </div>
          <div class="col-12 col-md-6">
            <span>Municipality / City</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.city_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[1]?.country_code === 'PHL'" class="form-control" formControlName="city_code" [attr.disabled]="!patientForm?.value?.addresses[1]?.state_province_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let muni of filterMunicipality(1)" [value]="muni?.code">
                {{ muni?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[1]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="city_code" placeholder="Enter City"/>
          </div>
        </div>
        <div class="row mt-3" formGroupName="1">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Barangay</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.barangay_code')?.errors?.required">*</b>
            <select *ngIf="patientForm?.value?.addresses[1]?.country_code === 'PHL'" class="form-control" formControlName="barangay_code" [attr.disabled]="!patientForm?.value?.addresses[1]?.city_code ? '' : null">
              <option value="null" hidden>Select</option>
              <option *ngFor="let barangay of filterBarangay(1)" [value]="barangay?.code">
                {{ barangay?.display }}
              </option>
            </select>
            <input *ngIf="patientForm?.value?.addresses[1]?.country_code !== 'PHL'" type="text" class="form-control" formControlName="barangay_code" placeholder="Enter Barangay"/>
          </div>
          <div class="col-12 col-md-6">
            <span>House / Lot / Building No.</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.line1')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="House / Lot / Building No." formControlName="line1"/>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 mb-3 mb-md-0">
            <span>Street / Purok / Sitio</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.line2')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Street / Purok / Sitio" formControlName="line2"/>
          </div>
          <div class="col-12 col-md-6">
            <span>Postal Code</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.zip_code')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Postal Code" formControlName="zip_code"/>
          </div>
          <div class="col-12 col-md-6 mt-3">
            <span>Notable Landmarks</span>
            <b class="text-red ml-2" *ngIf="!disableFields && getControl('addresses.1.landmark')?.errors?.required">*</b>
            <input type="text" class="form-control" placeholder="Notable Landmarks" formControlName="landmark"/>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>