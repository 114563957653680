<fieldset [disabled]="processing">
    <div class="container-fluid">
        <div class="row">
            <div class="col basic-anthro-wrapper">
                <div class="form-row" [formGroup]="form" *ngIf="form">
                    <div class="col-12 col-md-6 mb-3">
                        <span class="bold-500">Date and Time<b class="text-red ml-2" *ngIf="getControl('input_datetime')?.errors?.required">*</b></span>
                        <input type="datetime-local" class="form-control date-time-input" formControlName="input_datetime" max="9999-12-31T23:59">
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="bold-500">Height or Recumbent Length (cm)<b class="text-red ml-2" *ngIf="getControl('height')?.errors?.required">*</b></span>
                        <input type="number" step="2" class="form-control" formControlName="height" (change)="computeBmi()">
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="bold-500">Weight (kg)<b class="text-red ml-2" *ngIf="getControl('weight')?.errors?.required">*</b></span>
                        <input type="number" step="2" class="form-control" formControlName="weight" (change)="computeBmi()">
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <span class="bold-500">Body Mass Index (BMI; based on WHO Classification)</span>
                        <input type="text" class="form-control" readonly formControlName="body_mass_index">
                    </div>
                    <div class="col-12">
                        <button class="btn btn-primary mt-4" (click)="create()">Add Data</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col">
                <div >
                    <div class="med-hist-table-wrapper">
                        <div class="med-hist-table-scroller">
                            <table class="table table-bordered" style="border-top: 1px solid #dee2e6">
                                <thead>
                                    <tr>
                                        <th colspan="5" class="bg-header-gray hidden" style="border-radius: 0px;">Basic Anthropometric Measurements</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center bg-header-gray" style="border-radius: 0px;">Date and Time</th>
                                        <th class="text-center bg-header-gray" style="border-radius: 0px;">Height or Recumbent Length (cm)</th>
                                        <th class="text-center bg-header-gray" style="border-radius: 0px;">Weight (kg)</th>
                                        <th class="text-center bg-header-gray" style="border-radius: 0px;">Body Mass Index (BMI; based on WHO Classification)</th>
                                        <th class="text-center bg-header-gray" style="border-radius: 0px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody skeleton-loader [rows]="5" [columns]="5" *ngIf="processing"></tbody>
                                <tbody *ngIf="!processing">
                                    <tr *ngFor="let item of items?.data | paginate : {
                                        id: 'paginator',
                                        itemsPerPage: items?.per_page,
                                        currentPage: items?.current_page,
                                        totalItems: items?.total
                                    }; let i = index">
                                        <td>
                                            <span *ngIf="!item?.edit">{{ item?.input_datetime | date: 'MMM-dd-yyyy hh:mm a' }}</span>
                                            <input *ngIf="item?.edit" type="datetime-local" step="2" class="form-control" [(ngModel)]="item.input_datetime" max="9999-12-31T23:59">
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="!item?.edit">{{ item?.height }}</span>
                                            <input *ngIf="item?.edit" type="number" step="2" class="form-control" [(ngModel)]="item.edited_height">
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="!item?.edit">{{ item?.weight }}</span>
                                            <input *ngIf="item?.edit" type="number" step="2" class="form-control" [(ngModel)]="item.edited_weight">
                                        </td>
                                        <td class="text-center">
                                            {{ item?.bmi_string }}
                                        </td>
                                        <td>
                                            <div class="text-center">
                                                <button class="btn btn-primary mr-2" (click)="item.edit = true" *ngIf="!item?.edit"><i class="fas fa-edit mr-2"></i>Edit</button>
                                                <button class="btn btn-primary mr-2" (click)="update(item)" *ngIf="item?.edit"><i class="fas fa-save mr-2"></i>Save</button>
                                                <button class="btn btn-danger bordered-gray mr-2" (click)="onCancel(item)" *ngIf="item?.edit"><i class="fas fa-times mr-2"></i>Cancel</button>
                                                <button class="btn btn-danger" (click)="delete(item)" *ngIf="!item?.edit"><i class="fas fa-trash mr-2"></i>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr *ngIf="items?.data?.length < 1">
                                        <td colspan="5">No record</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="align-items-center d-flex justify-content-end mt-3 pagination-wrapper">
                        <div class="d-flex align-items-center pagination" *ngIf="!processing">
                          <select
                            class="form-control unbold ml-3"
                            style="margin-right: -20px; width: 80px; height: 30px; padding-top: 3px"
                            [(ngModel)]="perPage"
                            (change)="list(page)"
                          >
                            <option [ngValue]="per" *ngFor="let per of perPages">
                              {{ per }}
                            </option>
                          </select>
                          <pagination-controls
                            (pageChange)="list($event)"
                            id="paginator"
                          ></pagination-controls>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</fieldset>
