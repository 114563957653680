<div class="container-fluid">
    <div class="row">
        <div class="col px-0">
            <div class="card">
                <div class="card-header">
                    <h3 class="text-muted">Unavailable on Specific Dates and Time</h3>
                    <p class="text-muted">Use this to BLOCK OFF <strong class="text-danger">specific dates and
                            times</strong> from regular schedules, e.g. a morning family event, an afternoon meeting, a
                        whole day personal errand. Appointments cannot be set on these dates and times.</p>
                </div>
                <div class="card-body px-3">
                    <div [formGroup]="form">
                        <div *ngFor="let exception of exceptions?.controls; let i = index" formArrayName="exceptions"
                            class="exception-item mb-2">
                            <div class="schedule-container">
                                <div [formGroup]="exception" class="mb-3 px-0">
                                    <label>Enter Specific Date</label>
                                    <input [class.required-border]="exception?.get('exception_date')?.errors?.required"
                                        type="date" class="form-control" formControlName="exception_date"
                                        (change)="updateDates(exception)">
                                </div>
                                <div [formGroup]="exception" class="d-flex flex-wrap">
                                    <div class="col-6 pl-0 mb-3">
                                        <label>Start time<b class="text-red ml-2"
                                                *ngIf="exception?.get('effective_start_time')?.errors?.required">*</b></label>
                                        <input type="time" data-target="#reservationdate" data-form-type="date"
                                            class="form-control datetimepicker-input"
                                            formControlName="effective_start_time">
                                    </div>
                                    <div class="col-6 pr-0 mb-3">
                                        <label>End Time<b class="text-red ml-2"
                                                *ngIf="exception?.get('effective_end_time')?.errors?.required">*</b></label>
                                        <input type="time" data-target="#reservationdate" data-form-type="date"
                                            class="form-control datetimepicker-input"
                                            formControlName="effective_end_time">
                                    </div>
                                    <div class="col-12 px-0 mb-3">
                                        <label>Display Text<b class="text-red ml-2"
                                                *ngIf="exception?.get('exception_display')?.errors?.required">*</b></label>
                                        <input type="text" placeholder="Text" class="form-control"
                                            formControlName="exception_display">
                                    </div>
                                    <div class="col-12 px-0 mb-3">
                                        <label>Exception Note<b class="text-red ml-2"
                                                *ngIf="exception?.get('exception_note')?.errors?.required">*</b></label>
                                        <input type="text" placeholder="Notes" class="form-control"
                                            formControlName="exception_note">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox d-inline-block"
                                        (click)="isRepeatingChange(i)">
                                        <input class="custom-control-input cursor-pointer" type="checkbox" value="1"
                                            formControlName="is_repeating">
                                        <label for="is_repeating" class="custom-control-label cursor-pointer">Is
                                            Repeating</label>
                                    </div>
                                </div>
                                <div class="col-auto px-0 mb-3">
                                    <button class="btn btn-danger" (click)="removeException(i)">
                                        <i class="fas fa-trash"></i> Delete Schedule
                                    </button>
                                </div>

                            </div>

                        </div>
                        <div *ngIf="exceptions?.value?.length < 1" class="text-center">
                            No Schedule
                        </div>
                    </div>


                    <div class="mt-3">
                        <button class="btn btn-primary" (click)="addException()">
                            <i class="fas fa-plus mr-2"></i>Add Exception
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>