
<form [formGroup]="form" *ngIf="form">
    <fieldset [disabled]="processing">
        <div class="card">
            <div class="card-header">
                <div class="d-flex justify-content-between">
                    <span class="bold">SURGICAL HISTORY</span>
                    <a class="cursor-pointer" (click)="editMode = true" *ngIf="!editMode && !processing">Edit Details</a>
                </div>
            </div>
            <div class="card-body">
                <div class="med-hist-table-wrapper">
                    <div class="med-hist-table-scroller">
                        <table class="table table-bordered" *ngIf="!editMode">
                            <tbody style="border: none">
                                <tr style="border: none">
                                    <td class="no-border bold" style="width: 30%;">Operation/Procedure</td>
                                    <td class="no-border bold" style="width: 10%;">Date</td>
                                    <td class="no-border bold" style="width: 20%;">Indication</td>
                                    <td class="no-border bold" style="width: 20%;">Hospital</td>
                                    <td class="no-border bold" style="width: 20%;">Remarks</td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="5" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let item of items?.data">
                                    <td>{{ item?.operation_illness }}</td>
                                    <td>{{ item?.date }}</td>
                                    <td>{{ item?.indication }}</td>
                                    <td>{{ item?.hospital }}</td>
                                    <td>{{ item?.location_remarks }}</td>
                                </tr>
                                <tr *ngIf="items?.data?.length < 1">
                                    <td colspan="5">No Past Hospitalization/Surgeries</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="med-hist-table-wrapper">
                    <div class="med-hist-table-scroller">
                        <table class="table table-bordered" *ngIf="editMode">
                            <tbody class="no-border">
                                <tr class="no-border">
                                    <td class="no-border bold">Operation/Procedure</td>
                                    <td class="no-border bold">Date</td>
                                    <td class="no-border bold">Indication</td>
                                    <td class="no-border bold">Hospital</td>
                                    <td class="no-border bold">Remarks</td>
                                    <td></td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="4" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let control of hospitalizationSurgeries?.controls; let i = index" [formGroup]="control">
                                    <td><input type="text" class="form-control" formControlName="operation_illness"/></td>
                                    <td><input type="text" class="form-control" formControlName="date"/></td>
                                    <td><input type="text" class="form-control" formControlName="indication"/></td>
                                    <td><input type="text" class="form-control" formControlName="hospital"/></td>
                                    <td><input type="text" class="form-control" formControlName="location_remarks"/></td>
                                    <td>
                                        <button type="button" class="btn btn-default" (click)="add()" *ngIf="i === hospitalizationSurgeries?.controls?.length - 1"><i class="fas fa-plus text-primary"></i></button>
                                        <button type="button" class="btn btn-default" (click)="remove(i)" *ngIf="i < hospitalizationSurgeries?.controls?.length - 1"><i class="fas fa-minus text-danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-right mt-3" *ngIf="editMode">
                    <button type="button" class="btn btn-primary mr-2" (click)="save()">Save</button>
                    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </fieldset>
</form>