<p-dialog [baseZIndex]="2000" [breakpoints]="{'1440px': '600px', '768px': '90vw'}" [(visible)]="modal"
  [closable]="false" [closeOnEscape]="false" [dismissableMask]="false" [modal]="true" [position]="'center'"
  [draggable]="false">
  <ng-template pTemplate="header">
    <h3 class="text-primary bold-600">Select Field Category</h3>
  </ng-template>

  <select name="field_category" id="field_category" class="form-control" [(ngModel)]="field_category">
    <option value="">Select field category</option>
    <option value="{{option?.value}}" *ngFor="let option of fieldCategoryOptions">{{option?.label}}</option>
  </select>

  <ng-template pTemplate="footer">
    <a class="btn btn-primary mr-2" [routerLink]="['/settings/custom-templates/'+field_category+'/create']"
      [class.disabled]="field_category === ''">
      Select
    </a>
    <button role="button" class="btn btn-outline-primary" (click)="onClose()">
      Close
    </button>
  </ng-template>
</p-dialog>