<div class="card overflow-hidden">
    <div class="card-header bg-primary">
        <h3 class="card-title text-lg font-weight-bold">{{ onboarding ? 'Clinic Details' : 'Step 2: Clinic Details' }}</h3>
    </div>
    <div class="card-body px-3">
        <div class="container-fluid">
            <div class="row">
                <div class="col px-0">
                    <form [formGroup]="form">
                        <fieldset [disabled]="processing">
                            <div class="form-row">
                                <div class="form-group col-md-6 col-lg-7" formGroupName="clinic">
                                    <label for="clinicname">Clinic Name<b class="text-red ml-2" *ngIf="getControl('clinic.name_display')?.errors?.required">*</b></label>
                                    <input type="text" id="clinicname" class="form-control" formControlName="name_display">
                                </div>
                                <div class="form-group col-md-6 col-lg-5"formGroupName="contact">
                                    <label for="contact">Contact Number (Landline)<b class="text-red ml-2" *ngIf="getControl('contact.value')?.errors?.required">*</b></label>
                                    <input type="text" id="contact" class="form-control" formControlName="value">
                                </div>
                            </div>
                            <div class="form-group" formGroupName="address">
                                <label for="inputAddress">Address 1<b class="text-red ml-2" *ngIf="getControl('address.line1')?.errors?.required">*</b></label>
                                <input type="text" id="inputAddress" class="form-control" formControlName="line1">
                            </div>
                            <div class="form-group" formGroupName="address">
                                <label for="inputAddress2">Address 2<b class="text-red ml-2" *ngIf="getControl('address.line2')?.errors?.required">*</b></label>
                                <input type="text" id="inputAddress2" placeholder="" class="form-control" formControlName="line2">
                            </div>
                            <div class="form-row" formGroupName="address">
                                <div class="form-group col-md-4">
                                    <label for="inputState">Province<b class="text-red ml-2" *ngIf="getControl('address.state_code')?.errors?.required">*</b></label>
                                    <div *ngIf="provinceProcessing" class="text-center" style="border: 1px dashed #c3c3c3; border-radius: 5px; padding: .375rem 0.75rem;">
                                        <i class="ml-2 fas fa-circle-notch spinning"></i>
                                    </div>
                                    <select id="inputState" class="form-control" formControlName="state_code" (change)="listMunicipalities(); setProvince();" [class.hidden]="provinceProcessing">
                                        <option value="null">Select Province</option>
                                        <option *ngFor="let province of provinces?.data" [value]="province?.code">
                                            {{ province?.display }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="inputCity">City<b class="text-red ml-2" *ngIf="getControl('address.city_code')?.errors?.required">*</b></label>
                                    <div *ngIf="muniProcessing" class="text-center" style="border: 1px dashed #c3c3c3; border-radius: 5px; padding: .375rem 0.75rem;">
                                        <i class="ml-2 fas fa-circle-notch spinning"></i>
                                    </div>
                                    <select id="inputState" class="form-control" formControlName="city_code" [attr.disabled]="!form?.value?.address?.state_code ? '' : null" (change)="setCity()" [class.hidden]="muniProcessing">
                                        <option value="null">Select City</option>
                                        <option *ngFor="let city of municipalities?.data" [value]="city?.code">
                                            {{ city?.display }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group col-md-2">
                                    <label for="inputZip">Zip<b class="text-red ml-2" *ngIf="getControl('address.zip_code')?.errors?.required">*</b></label>
                                    <input type="text" id="inputZip" class="form-control" formControlName="zip_code" [attr.disabled]="(!form?.value?.address?.state_code || !form?.value?.address?.city_code) ? '' : null">
                                </div>
                            </div>
                            <div class="text-right">
                                <button type="button" class="btn" [class.btn-primary]="!clinic" [class.btn-warning]="clinic" (click)="save()" [disabled]="getControl('clinic')?.invalid || getControl('address')?.invalid || getControl('contact')?.invalid">{{ clinic ? 'Update Clinic Details' : 'Save Clinic Details' }}</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>