<p-dialog
  [baseZIndex]="2000"
  [(visible)]="modal"
  [closable]="false"
  [closeOnEscape]="false"
  [dismissableMask]="false"
  [modal]="true"
  [position]="'center'"
  [draggable]="false"
>
    <fieldset [disabled]="processing">
        <div class="pr-3 pl-3">
            <h3 class="text-primary bold" >Discharge Patient</h3>
    
            <div class="form-group">
                <small class="text-muted">Date of Discharge <b class="ml-2 text-danger" *ngIf="!date">*</b></small>
                <input type="datetime-local" class="form-control" [(ngModel)]="date"  >
            </div>      
            <div class="form-group">
                <small class="text-muted">Discharge Remarks</small>
                <input type="text" class="form-control" [(ngModel)]="remarks">
            </div>  
        </div>
    </fieldset>
    <p-footer>
        <div class="w-100 text-right pr-3 pl-3 pb-3">
        <button role="button" class="btn btn-primary btn-lg mr-2" (click)="dischargePatient()" [disabled]="processing">
            Discharge
            <i *ngIf="processing" class="ml-2 nav-icon fas fa-circle-notch spinning"></i>
        </button>
        <button role="button" class="btn btn-outline-primary btn-lg" (click)="onClose()" [disabled]="processing">
            Close
        </button>
        </div>
    </p-footer>
</p-dialog>
