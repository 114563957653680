<fieldset [disabled]="processing">
    <div class="container-fluid px-1">
        <div class="row mt-4">
            <div class="col pl-md-0">
                <div class="card overflow-hidden">
                    <div class="card-header bg-primary">
                        <h3 class="card-title text-lg font-weight-bold">{{ onboarding ? 'Add Clinic' : 'Step 1: Choose a Clinic' }}</h3>
                    </div>
                    <div class="card-body px-3">
                        <div class="row">
                            <div class="col">
                                <div class="form-group">
                                    <select id="exampleFormControlSelect1" class="form-control" [(ngModel)]="selectedClinic" (change)="onClinicChange()">
                                        <option selected [ngValue]="undefined">- Add New Physical Clinic -</option>
                                        <option [ngValue]="clinic" *ngFor="let clinic of clinics">{{ clinic?.name_display }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="form">
            <div class="col pl-md-0">
                <app-clinic-detail [onboarding]="onboarding" [form]="form" [clinic]="selectedClinic" [clinics]="clinics" [doctor]="doctor" (saved)="setClinic($event)" #clinicInfo></app-clinic-detail>
            </div>
        </div>
        <div class="row" *ngIf="form && selectedClinic">
            <div class="col pl-md-0">
                <app-clinic-manage-schedule [onboarding]="onboarding" [form]="form" [clinic]="selectedClinic" [clinics]="clinics" [doctor]="doctor" [serviceItems]="serviceItems" #manageSchedule (deleted)="delete($event)" (saved)="updateClinic($event)"></app-clinic-manage-schedule>
            </div>
        </div>
    </div>
</fieldset>