import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CCON } from 'src/app/utils/items/service-type';

@Component({
  selector: 'app-clinic-specific-schedule',
  templateUrl: './clinic-specific-schedule.component.html',
  styleUrls: ['./clinic-specific-schedule.component.scss'],
})
export class ClinicSpecificScheduleComponent implements OnInit, OnChanges {
  @Output()
  delete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  deleteSlot: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  doctor: any;

  @Input()
  form: FormGroup;

  @Input()
  clinic: any;

  @Input()
  clinics: any;

  @Input()
  serviceItems: any;

  @Input()
  serviceForm: FormGroup;

  @Input()
  selectedService: any;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.form) {
      this.setSchedules();
    }
    if (changes?.selectedService) {
      this.setSchedules();
    }
  }

  ngOnInit(): void {}

  setSchedules() {
    this.schedules.clear();
    const clinicService = this.clinic?.resource?.services?.find(
      (service) =>
        service?.service_item_code === this.selectedService?.service_item_code
    );

    clinicService?.specific_schedules?.forEach((schedule) => {
      const scheduleForm = this.createSchedule(schedule);
      this.schedules.push(scheduleForm);

      schedule?.slots?.forEach((slot) => {
        const slots = scheduleForm?.get('slots') as FormArray;
        slots?.push(this.createSlot(slot));
      });
    });
  }

  get schedules() {
    return this.serviceForm.get('specific_schedules') as FormArray;
  }

  addSchedule() {
    this.schedules.push(this.createSchedule());
  }

  addSlot(schedule) {
    const slots = schedule?.get('slots') as FormArray;
    slots.push(this.createSlot());
  }

  removeSchedule(index) {
    this.delete.emit(this.schedules?.value?.[index]?.id);
    this.schedules.removeAt(index);
  }

  removeSlot(schedule, index) {
    const slots = schedule?.get('slots') as FormArray;
    this.deleteSlot.emit(slots?.value?.[index]?.id);
    slots.removeAt(index);
  }

  createSchedule(schedule = null) {
    return this.fb.group({
      id: [schedule?.id],
      schedule_type: ['specific'],
      service_type_code: [CCON, Validators.required],
      schedule_date: [
        moment(schedule?.schedule_date)?.format('YYYY-MM-DD') || null,
        Validators.required,
      ],
      schedule_start_time: [schedule?.schedule_start_time],
      schedule_end_time: [schedule?.schedule_end_time],
      remarks: [schedule?.remarks],
      interval: [schedule?.interval || 1, Validators.required],
      slots: this.fb.array([], [Validators.required, Validators.min(1)]),
    });
  }

  createSlot(slot = null) {
    return this.fb.group({
      id: [slot?.id],
      slot_type: ['generic'],
      slot_start_time: [slot?.slot_start_time, Validators.required],
      slot_end_time: [slot?.slot_end_time, Validators.required],
      slot_duration: [slot?.slot_duration || 1, Validators.required],
      slot_notes: [slot?.slot_notes],
      number_of_bookings: [slot?.number_of_bookings || 1],
      allow_overbooking: slot?.allow_overbooking || [0],
      override_generic: [slot?.override_generic || 0],
    });
  }

  convertTimeToDate(time: string): Date {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds ?? 0, 0);

    return date;
  }
}
