<form [formGroup]="form" *ngIf="form">
    <fieldset [disabled]="processing">
        <div class="card" [class.no-border]="modal">
            <div class="card-header" *ngIf="!modal">
                <div class="d-flex justify-content-between">
                    <span class="bold">ALLERGIES</span>
                    <a class="cursor-pointer" (click)="editMode = true" *ngIf="!editMode && !processing">Edit
                        Details</a>
                </div>
            </div>
            <div class="card-body row" [class.p-0]="modal">
                <div class="col-12 col-md-6">
                    <section class="pt-3">
                        <!-- <span class="bold">MEDICATION/DRUG ALLERGY</span> -->
                        <table class="table table-sm table-bordered mt-2" *ngIf="!editMode">
                            <tbody style="border: none">
                                <tr style="border: none">
                                    <td class="no-border bold">Drug</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="3" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let item of getAllergyByCategory(allergy?.medication?.uid)">
                                    <td>{{ item?.substance }}</td>
                                    <td class="hidden">{{ item?.reaction }}</td>
                                    <td class="hidden">{{ getType(item?.allergy_type_uid) }}</td>
                                </tr>
                                <tr *ngIf="getAllergyByCategory(allergy?.medication?.uid)?.length < 1">
                                    <td colspan="3">No Allergy</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered" *ngIf="editMode">
                            <tbody class="no-border">
                                <tr class="no-border">
                                    <td class="no-border bold">Drug</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                    <td style="width:1%"></td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="4" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let control of getAllergyControlByCategory(allergy?.medication?.uid); let i = index"
                                    [formGroup]="control">
                                    <td><input type="text" class="form-control" formControlName="substance" /></td>
                                    <td class="hidden"><input type="text" class="form-control"
                                            formControlName="reaction" />
                                    </td>
                                    <td class="hidden">
                                        <select class="form-control" formControlName="allergy_type_uid">
                                            <option value="null" hidden>Select</option>
                                            <option [value]="type?.uid" *ngFor="let type of types">{{ type?.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="add(allergy?.medication?.uid)"
                                            *ngIf="i === getAllergyControlByCategory(allergy?.medication?.uid)?.length - 1"><i
                                                class="fas fa-plus text-primary"></i></button>
                                        <button class="btn btn-default" type="button" (click)="remove(control)"
                                            *ngIf="i < getAllergyControlByCategory(allergy?.medication?.uid)?.length - 1"><i
                                                class="fas fa-minus text-danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <!-- <span class="bold">FOOD ALLERGY</span> -->
                        <table class="table table-sm table-bordered mt-2" *ngIf="!editMode">
                            <tbody style="border: none">
                                <tr style="border: none">
                                    <td class="no-border bold">Food</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="1" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let item of getAllergyByCategory(allergy?.food?.uid)">
                                    <td>{{ item?.substance }}</td>
                                    <td class="hidden">{{ item?.reaction }}</td>
                                    <td class="hidden">{{ getType(item?.allergy_type_uid) }}</td>
                                </tr>
                                <tr *ngIf="getAllergyByCategory(allergy?.food?.uid)?.length < 1">
                                    <td colspan="3">No Allergy</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered" *ngIf="editMode">
                            <tbody class="no-border">
                                <tr class="no-border">
                                    <td class="no-border bold">Food</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                    <td style="width:1%"></td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="1" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let control of getAllergyControlByCategory(allergy?.food?.uid); let i = index"
                                    [formGroup]="control">
                                    <td><input type="text" class="form-control" formControlName="substance" /></td>
                                    <td class="hidden"><input type="text" class="form-control"
                                            formControlName="reaction" />
                                    </td>
                                    <td class="hidden">
                                        <select class="form-control" formControlName="allergy_type_uid">
                                            <option value="null" hidden>Select</option>
                                            <option [value]="type?.uid" *ngFor="let type of types">{{ type?.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button" (click)="add(allergy?.food?.uid)"
                                            *ngIf="i === getAllergyControlByCategory(allergy?.food?.uid)?.length - 1"><i
                                                class="fas fa-plus text-primary"></i></button>
                                        <button class="btn btn-default" type="button" (click)="remove(control)"
                                            *ngIf="i < getAllergyControlByCategory(allergy?.food?.uid)?.length - 1"><i
                                                class="fas fa-minus text-danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
                <div class="col-12 col-md-6">
                    <section class="pt-3">
                        <!-- <span class="bold">ENVIRONMENT ALLERGY</span> -->
                        <table class="table table-sm table-bordered mt-2" *ngIf="!editMode">
                            <tbody style="border: none">
                                <tr style="border: none">
                                    <td class="no-border bold">Contact</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="3" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let item of getAllergyByCategory(allergy?.environment?.uid)">
                                    <td>{{ item?.substance }}</td>
                                    <td class="hidden">{{ item?.reaction }}</td>
                                    <td class="hidden">{{ getType(item?.allergy_type_uid) }}</td>
                                </tr>
                                <tr *ngIf="getAllergyByCategory(allergy?.environment?.uid)?.length < 1">
                                    <td colspan="3">No Allergy</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered" *ngIf="editMode">
                            <tbody class="no-border">
                                <tr class="no-border">
                                    <td class="no-border bold">Contact</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                    <td style="width:1%"></td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="4" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let control of getAllergyControlByCategory(allergy?.environment?.uid); let i = index"
                                    [formGroup]="control">
                                    <td><input type="text" class="form-control" formControlName="substance" /></td>
                                    <td class="hidden"><input type="text" class="form-control"
                                            formControlName="reaction" />
                                    </td>
                                    <td class="hidden">
                                        <select class="form-control" formControlName="allergy_type_uid">
                                            <option value="null" hidden>Select</option>
                                            <option [value]="type?.uid" *ngFor="let type of types">{{ type?.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="add(allergy?.environment?.uid)"
                                            *ngIf="i === getAllergyControlByCategory(allergy?.environment?.uid)?.length - 1"><i
                                                class="fas fa-plus text-primary"></i></button>
                                        <button class="btn btn-default" type="button" (click)="remove(control)"
                                            *ngIf="i < getAllergyControlByCategory(allergy?.environment?.uid)?.length - 1"><i
                                                class="fas fa-minus text-danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                    <section>
                        <!-- <span class="bold">BIOLOGIC ALLERGY</span> -->
                        <table class="table table-sm table-bordered mt-2" *ngIf="!editMode">
                            <tbody style="border: none">
                                <tr style="border: none">
                                    <td class="no-border bold">Other</td>
                                    <td class="no-border bold hidden">Reaction</td>
                                    <td class="no-border bold hidden">Type</td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="3" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let item of getAllergyByCategory(allergy?.biologic?.uid)">
                                    <td>{{ item?.substance }}</td>
                                    <td class="hidden">{{ item?.reaction }}</td>
                                    <td class="hidden">{{ getType(item?.allergy_type_uid) }}</td>
                                </tr>
                                <tr *ngIf="getAllergyByCategory(allergy?.biologic?.uid)?.length < 1">
                                    <td colspan="3">No Allergy</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered" *ngIf="editMode">
                            <tbody class="no-border">
                                <tr class="no-border">
                                    <td class="no-border bold">Other</td>
                                    <td class="no-border bold hidden">REACTION</td>
                                    <td class="no-border bold hidden">TYPE</td>
                                    <td style="width:1%"></td>
                                </tr>
                            </tbody>
                            <tbody skeleton-loader [rows]="3" [columns]="4" *ngIf="processing"></tbody>
                            <tbody *ngIf="!processing">
                                <tr *ngFor="let control of getAllergyControlByCategory(allergy?.biologic?.uid); let i = index"
                                    [formGroup]="control">
                                    <td><input type="text" class="form-control" formControlName="substance" /></td>
                                    <td class="hidden"><input type="text" class="form-control"
                                            formControlName="reaction" />
                                    </td>
                                    <td class="hidden">
                                        <select class="form-control" formControlName="allergy_type_uid">
                                            <option value="null" hidden>Select</option>
                                            <option [value]="type?.uid" *ngFor="let type of types">{{ type?.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <button class="btn btn-default" type="button"
                                            (click)="add(allergy?.biologic?.uid)"
                                            *ngIf="i === getAllergyControlByCategory(allergy?.biologic?.uid)?.length - 1"><i
                                                class="fas fa-plus text-primary"></i></button>
                                        <button class="btn btn-default" type="button" (click)="remove(control)"
                                            *ngIf="i < getAllergyControlByCategory(allergy?.biologic?.uid)?.length - 1"><i
                                                class="fas fa-minus text-danger"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
                <div class="text-right pt-3 w-100" *ngIf="editMode && !modal">
                    <button type="button" class="btn btn-primary mr-2" (click)="save()">Save</button>
                    <button type="button" class="btn btn-default" (click)="cancel()">Cancel</button>
                </div>
            </div>
        </div>
    </fieldset>
</form>